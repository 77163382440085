module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"inttiruoka.fi","short_name":"Inttiruoka","description":"Puolustusvoimien ruokalistat nopeasti","start_url":"/","background_color":"#18447e","theme_color":"#18447e","lang":"fi","display":"standalone","icon":"src/images/logo_app.svg","icons":[{"src":"/static/images/logo_maskable.png","sizes":"512x512","type":"image/png","purpose":"any"}],"related_applications":[{"platform":"play","url":"https://play.google.com/store/apps/details?id=fi.inttiruoka","id":"fi.inttiruoka"}],"categories":["food"],"orientation":"any","screenshots":[{"src":"/screenshots/1.png","sizes":"411x823","type":"image/png"},{"src":"/screenshots/2.png","sizes":"409x823","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1075d040fa1a607f1d799aaa5711ed24"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WP7KGC8KDQ"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1}}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
